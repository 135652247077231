#root .popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 9999;
}

#root .popup .card {
  min-width: 360px;
  background-color: white;
  border-radius: 8px;
  padding: 0 10px;
  padding-top: 32px;
  padding-bottom: 14px;
}

#root .popup .card > .title {
  font-size: 14px;
  color: #2b2b2b;
  text-align: center;
  line-height: 1.5;
}

#root .popup .card .interactions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  margin-top: 28px;
}

#root .popup .card .interactions .btn {
  cursor: pointer;
  height: 36px;
  border-radius: 8px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #afafaf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#root .popup .card .interactions .btn span {
  font-size: 14px;
}

#root .popup .card .interactions .btn-white {
  color: white;
}

#root .popup .card .interactions .btn-black {
  color: #2b2b2b;
}

#root .popup .card .interactions .btn-back-main {
  background-color: #2b2b2b;
}

#root .popup .card .interactions .btn-back-grey {
  background-color: #afafaf;
}
