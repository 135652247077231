#root {


  .router-wrapper {

    height: 100vh;
    overflow: hidden;

    .main-section {
      flex: 1;
      display: flex;
      overflow-y: hidden;
      flex-direction: column;
      height: 100%;


      &>.container {
        flex: 1;
        // min-height: unset;
        // overflow-y: scroll;
      }
    }
  }
}