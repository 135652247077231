* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: none;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-break: break-all;
}

*::-webkit-scrollbar {
  display: none;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh !important;
  color: #292a2f;
  background-color: white;
}

.container {
  min-height: 100vh;
}

.txt-M {
  font-weight: 500;
}

.txt-B {
  font-weight: 700;
}

input:focus {
  outline: none;
}

input::-webkit-input-placeholder {
  color: #707070;
}

input:-ms-input-placeholder {
  color: #707070;
}

input::-ms-input-placeholder {
  color: #707070;
}

input::placeholder {
  color: #707070;
}

.asset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

img {
  width: 100%;
  display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#recaptcha-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #a5a5a5;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #a5a5a5;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #a5a5a5;
}

input::placeholder,
textarea::placeholder {
  color: #a5a5a5;
}

input:disabled {
  color: #707070;
  background-color: #707070;
  border: none;
}

input:focus,
textarea:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: #292a2f;
}

.ellipsis-1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ani-closeup {
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ani-closeup:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.ani-slide-right {
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.ani-slide-right:hover {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
