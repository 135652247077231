$main: #62D669;
$black: #2b2b2b;
$grey-3: #afafaf;

#root {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .card {
      min-width: 360px;
      background-color: white;
      border-radius: 8px;
      padding: 0 10px;
      padding-top: 32px;
      padding-bottom: 14px;

      &>.title {
        font-size: 14px;
        color: $black;
        text-align: center;
        line-height: 1.5;
      }

      .interactions {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 28px;

        .btn {
          cursor: pointer;
          height: 36px;
          border-radius: 8px;
          flex: 1;
          align-items: center;
          justify-content: center;
          background-color: $grey-3;
          display: flex;

          span {
            font-size: 14px;

          }

        }

        .btn-white {
          color: white;
        }

        .btn-black {
          color: $black;
        }

        .btn-back-main {
          background-color: $black;
        }

        .btn-back-grey {
          background-color: $grey-3;
        }
      }
    }
  }
}