#root .loading {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

#root .loading .loading-area {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  border-radius: 16px;
  padding: 24px;
  font-size: 120px;
  font-weight: 700;
  color: white;
}
