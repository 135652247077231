#root .router-wrapper {
  height: 100vh;
  overflow: hidden;
}

#root .router-wrapper .main-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-y: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

#root .router-wrapper .main-section > .container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
