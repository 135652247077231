#root {
  .loading {
    position: fixed;
    // background-color: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .loading-area {
      background-color: rgba($color: #000000, $alpha: 0.6);
      padding: 4px;
      border-radius: 16px;
      padding: 24px;
      font-size: 120px;
      font-weight: 700;
      color: white;
    }
  }
}