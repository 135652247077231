$black1: #292a2f;
$black2: #1a1b1e;
$black3: #2b2b2b;
$gray1: #707070;
$red: #f21c4d;

* {
  box-sizing: border-box;
  border: none;
  font-family: Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Noto Sans KR',
    'Malgun Gothic',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  &::-webkit-scrollbar {
    display: none;
  }

  word-break: break-all;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh !important;
  color: $black1;
  background-color: white;
}

.container {
  min-height: 100vh;

  // .top-line {
  //   height: unquote('max(calc(32/1366 * 100vw),32px)');
  //   background-color: $main;
  // }
}

.txt-M {
  font-weight: 500;
}

.txt-B {
  font-weight: 700;
}

input {
  &:focus {
    outline: none;

  }

  &::placeholder {
    color: $gray1;
  }
}

.asset {
  display: flex;
  align-items: center;
}

img {
  width: 100%;
  display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#recaptcha-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba($color: #000000, $alpha: 0.4);
  position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translateX(-50%) translateY(-50%);

  // bottom: 0;
  // right: 0;
}




input::placeholder,
textarea::placeholder {
  color: #a5a5a5;
}

input:disabled {
  color: $gray1;
  background-color: $gray1;
  border: none;

}

input:focus,
textarea:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: $black1;
}


.ellipsis-1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ani-closeup {
  transition: transform .2s ease-in-out;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  }
}

.ani-slide-right {
  transition: transform .2s ease-in-out;
  transform: translateX(0);

  &:hover {
    transform: translateX(10px);
  }

}