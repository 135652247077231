#root .app-list .wrapper {
  width: 1440px;
  margin: 0 auto;
}

#root .app-list .wrapper .sticky-section {
  padding-top: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

#root .app-list .wrapper .sticky-section > .top-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#root .app-list .wrapper .sticky-section > .top-section > .title {
  font-size: 40px;
  color: #292a2f;
}

#root .app-list .wrapper .sticky-section > .top-section .add-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #292a2f;
  border-radius: 8px;
  width: 160px;
  height: 56px;
  cursor: pointer;
}

#root .app-list .wrapper .sticky-section > .top-section .add-btn span {
  color: white;
  font-size: 24px;
}

#root .app-list .wrapper .sticky-section .list-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 8px;
  height: 80px;
  background-color: #292a2f;
  padding: 0 20px;
  margin-top: 20px;
  gap: 32px;
}

#root .app-list .wrapper .sticky-section .list-header .l-h-e {
  color: white;
  font-size: 24px;
  text-align: center;
}

#root .app-list .wrapper .sticky-section .list-header .name {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

#root .app-list .wrapper .sticky-section .list-header .platform {
  width: 100px;
}

#root .app-list .wrapper .sticky-section .list-header .modify {
  width: 130px;
}

#root .app-list .wrapper .sticky-section .list-header .status {
  width: 80px;
}

#root .app-list .wrapper > .list {
  padding-bottom: 52px;
}

#root .app-list .wrapper > .list .app-card {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
  font-size: 24px;
  gap: 32px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}

#root .app-list .wrapper > .list .app-card .icon {
  width: 56px;
  aspect-ratio: 1/1;
  border-radius: 8px;
  background-color: #2b2b2b;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

#root .app-list .wrapper > .list .app-card .name {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

#root .app-list .wrapper > .list .app-card .platform {
  width: 100px;
  text-align: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

#root .app-list .wrapper > .list .app-card .modify {
  width: 130px;
  text-align: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

#root .app-list .wrapper > .list .app-card .status {
  width: 80px;
  text-align: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
