$black1: #292a2f;
$black2: #1a1b1e;
$black3: #2b2b2b;
$gray1: #707070;
$red: #f21c4d;

#root {
  .app-list {
    .wrapper {
      width: 1440px;
      margin: 0 auto;

      .sticky-section {
        padding-top: 40px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;

        &>.top-section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &>.title {
            font-size: 40px;
            color: $black1;
          }

          .add-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $black1;
            border-radius: 8px;
            width: 160px;
            height: 56px;
            cursor: pointer;

            span {
              color: white;
              font-size: 24px;
            }
          }
        }

        .list-header {
          display: flex;
          align-items: center;
          border-radius: 8px;
          height: 80px;
          background-color: $black1;
          padding: 0 20px;
          margin-top: 20px;
          gap: 32px;

          .l-h-e {
            color: white;
            font-size: 24px;
            text-align: center;
          }

          .name {
            flex: 1;
            text-align: center;
          }

          .platform {
            width: 100px;
          }

          .modify {
            width: 130px;
          }

          .status {
            width: 80px;
          }
        }
      }


      &>.list {
        padding-bottom: 52px;

        .app-card {
          height: 100px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          font-size: 24px;
          gap: 32px;
          border-bottom: 1px solid #e5e5e5;
          cursor: pointer;

          .icon {
            width: 56px;
            aspect-ratio: 1/1;
            border-radius: 8px;
            background-color: $black3;
            flex-shrink: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
          }

          .name {
            flex: 1;
            text-align: center;

          }

          .platform {
            width: 100px;
            text-align: center;

            flex-shrink: 0;
          }

          .modify {
            width: 130px;

            text-align: center;
            flex-shrink: 0;
          }

          .status {
            width: 80px;
            text-align: center;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}