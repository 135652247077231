#root .create-app {
  width: 1440px;
  margin: 0 auto;
}

#root .create-app > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

#root .create-app > .wrapper > .top-section {
  padding-top: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#root .create-app > .wrapper > .top-section > .title {
  font-size: 40px;
  color: #292a2f;
}

#root .create-app > .wrapper > .top-section > .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

#root .create-app > .wrapper > .top-section > .right .add-screen-shots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #292a2f;
  border-radius: 8px;
  width: 160px;
  height: 56px;
  cursor: pointer;
}

#root .create-app > .wrapper > .top-section > .right .add-screen-shots label {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#root .create-app > .wrapper > .top-section > .right .add-screen-shots label span {
  color: white;
  font-size: 20px;
}

#root .create-app > .wrapper > .top-section > .right .add-screen-shots input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

#root .create-app > .wrapper > .top-section > .right .add-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #292a2f;
  border-radius: 8px;
  width: 160px;
  height: 56px;
  cursor: pointer;
}

#root .create-app > .wrapper > .top-section > .right .add-btn span {
  color: white;
  font-size: 20px;
}

#root .create-app > .wrapper > .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  gap: 20px;
  margin-top: 32px;
}

#root .create-app > .wrapper > .content .left {
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px;
}

#root .create-app > .wrapper > .content .left .info > .title {
  font-size: 16px;
}

#root .create-app > .wrapper > .content .left .info .info-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 12px;
  gap: 40px;
}

#root .create-app > .wrapper > .content .left .info .info-content .icon {
  width: 220px;
  aspect-ratio: 1/1;
  background-color: #f4f4f4;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #707070;
}

#root .create-app > .wrapper > .content .left .info .info-content .icon label {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

#root .create-app > .wrapper > .content .left .info .info-content .icon input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .platform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .platform div {
  width: 88px;
  height: 36px;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #1a1b1e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .platform div span {
  font-size: 16px;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .platform .selected {
  background-color: #1a1b1e;
  border: none;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .platform .selected span {
  color: white;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .inp {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: 20px;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .inp > .tilte {
  font-size: 16px;
}

#root .create-app > .wrapper > .content .left .info .info-content .info-basic .inp input {
  margin-top: 4px;
  border-radius: 8px;
  padding: 0 12px;
  font-size: 16px;
  border: 1px solid #a5a5a5;
  height: 44px;
  width: 100%;
}

#root .create-app > .wrapper > .content .left .screen-list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}

#root .create-app > .wrapper > .content .left .screen-list .screen-list-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  padding: 0 12px;
  padding-top: 20px;
  padding-bottom: 52px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  -webkit-column-gap: 12px;
          column-gap: 12px;
  row-gap: 24px;
}

#root .create-app > .wrapper > .content .left .screen-list .screen-list-wrapper .screenshot {
  aspect-ratio: 750/1624;
  background-color: white;
  border-radius: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

#root .create-app > .wrapper > .content .left .screen-list .screen-list-wrapper .flow-section {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
}

#root .create-app > .wrapper > .content .left .screen-list .screen-list-wrapper .flow-section > .title {
  font-size: 24px;
}

#root .create-app > .wrapper > .content .left .screen-list .screen-list-wrapper .flow-section .page-list {
  margin-top: 12px;
  background-color: #f4f4f4;
  border-radius: 24px;
  padding: 16px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  overflow-x: scroll;
}

#root .create-app > .wrapper > .content .left .screen-list .screen-list-wrapper .flow-section .page-list .page {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 280px;
  aspect-ratio: 750/1624;
  background-color: white;
  border-radius: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

#root .create-app > .wrapper > .content .right {
  width: 420px;
  height: 100%;
  border-left: 1px solid #e5e5e5;
  padding-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: none;
}

#root .create-app > .wrapper > .content .right > .top-section > .title {
  font-size: 16px;
}

#root .create-app > .wrapper > .content .right > .top-section .desc {
  font-size: 16px;
  margin-top: 8px;
  color: #a5a5a5;
}

#root .create-app > .wrapper > .content .right > .top-section input {
  height: 44px;
  padding: 0 12px;
  font-size: 16px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  margin-top: 8px;
  width: 100%;
}

#root .create-app > .wrapper > .content .right > .list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper {
  padding: 12px 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .app-card {
  cursor: pointer;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
  padding: 0 12px;
  height: 72px;
  gap: 8px;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #E5E5E5;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .app-card .app-icon {
  width: 52px;
  height: 52px;
  border-radius: 8px;
  background-color: #707070;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .app-card .text-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .app-card .text-section > .title {
  font-size: 16px;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .app-card .text-section .app-platform {
  margin-top: 4px;
  font-size: 16px;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .app-card:hover {
  background-color: #292a2f;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .app-card:hover .text-section {
  color: white;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .selected {
  background-color: #292a2f;
}

#root .create-app > .wrapper > .content .right > .list > .wrapper .selected .text-section {
  color: white;
}
