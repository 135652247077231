$black1: #292a2f;
$black2: #1a1b1e;
$black3: #2b2b2b;
$gray1: #707070;
$red: #f21c4d;

#root {
  .create-app {
    width: 1440px;
    margin: 0 auto;

    &>.wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;

      &>.top-section {
        padding-top: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // position: sticky;
        // top: 0;
        // background-color: white;


        &>.title {
          font-size: 40px;
          color: $black1;
        }

        &>.right {
          display: flex;
          align-items: center;
          gap: 16px;

          .add-screen-shots {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $black1;
            border-radius: 8px;
            width: 160px;
            height: 56px;
            cursor: pointer;

            label {
              width: 100%;
              height: 100%;
              display: flex;
              cursor: pointer;
              align-items: center;
              justify-content: center;

              span {
                color: white;
                font-size: 20px;
              }
            }

            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              height: 0;
            }


          }

          .add-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $black1;
            border-radius: 8px;
            width: 160px;
            height: 56px;
            cursor: pointer;

            span {
              color: white;
              font-size: 20px;
            }
          }
        }
      }

      &>.content {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 20px;
        margin-top: 32px;

        .left {
          height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 40px;

          .info {
            &>.title {
              font-size: 16px;
            }

            .info-content {
              display: flex;
              align-items: flex-start;
              margin-top: 12px;
              gap: 40px;

              .icon {
                width: 220px;
                aspect-ratio: 1/1;
                background-color: #f4f4f4;
                border-radius: 24px;
                position: relative;
                overflow: hidden;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border: 2px solid $gray1;

                label {
                  width: 100%;
                  height: 100%;
                  display: block;
                  cursor: pointer;
                }

                input {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 0;
                  height: 0;
                }
              }

              .info-basic {
                flex: 1;

                .platform {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  div {
                    width: 88px;
                    height: 36px;
                    background-color: white;
                    border-radius: 8px;
                    border: 2px solid $black2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    span {
                      font-size: 16px;

                    }
                  }

                  .selected {
                    background-color: $black2;
                    border: none;

                    span {
                      color: white;
                    }
                  }
                }




                .inp {
                  flex: 1;
                  margin-top: 20px;

                  &>.tilte {
                    font-size: 16px;
                  }

                  input {
                    margin-top: 4px;
                    border-radius: 8px;
                    padding: 0 12px;
                    font-size: 16px;
                    border: 1px solid #a5a5a5;
                    height: 44px;
                    width: 100%;
                  }
                }
              }
            }
          }

          .screen-list {
            flex: 1;
            position: relative;

            .screen-list-wrapper {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              overflow-y: scroll;
              padding: 0 12px;
              padding-top: 20px;
              padding-bottom: 52px;
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              column-gap: 12px;
              row-gap: 24px;


              .screenshot {
                aspect-ratio: 750/1624;
                background-color: white;
                border-radius: 24px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
              }

              // 나중에 할것
              .flow-section {
                flex-shrink: 0;
                cursor: pointer;

                &>.title {
                  font-size: 24px;
                }

                .page-list {
                  margin-top: 12px;
                  background-color: #f4f4f4;
                  border-radius: 24px;
                  padding: 16px 20px;

                  display: flex;
                  gap: 12px;
                  overflow-x: scroll;

                  .page {
                    flex-shrink: 0;
                    width: 280px;
                    aspect-ratio: 750/1624;
                    background-color: white;
                    border-radius: 24px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
                  }
                }
              }
            }
          }
        }

        .right {
          width: 420px;
          height: 100%;
          border-left: 1px solid #e5e5e5;
          padding-left: 20px;

          display: flex;
          flex-direction: column;
          display: none;

          &>.top-section {
            &>.title {
              font-size: 16px;

            }

            .desc {
              font-size: 16px;
              margin-top: 8px;
              color: #a5a5a5;
            }

            input {
              height: 44px;
              padding: 0 12px;
              font-size: 16px;
              border: 1px solid #E5E5E5;
              border-radius: 8px;
              margin-top: 8px;
              width: 100%;


            }
          }

          &>.list {
            flex: 1;
            position: relative;

            &>.wrapper {
              padding: 12px 0;
              position: absolute;

              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              overflow-y: scroll;
              display: flex;
              flex-direction: column;
              gap: 8px;


              .app-card {
                cursor: pointer;
                flex-shrink: 0;
                display: flex;
                border-radius: 8px;
                padding: 0 12px;
                height: 72px;
                gap: 8px;
                width: 100%;
                align-items: center;
                border: 1px solid #E5E5E5;

                .app-icon {
                  width: 52px;
                  height: 52px;
                  border-radius: 8px;
                  background-color: $gray1;
                  flex-shrink: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                }

                .text-section {
                  flex: 1;

                  &>.title {
                    font-size: 16px;
                  }

                  .app-platform {
                    margin-top: 4px;
                    font-size: 16px;
                  }
                }

                &:hover {
                  background-color: $black1;

                  .text-section {
                    color: white;
                  }
                }
              }

              .selected {
                background-color: $black1;

                .text-section {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}